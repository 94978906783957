/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import { Layout, SEO, ContentfulRichText } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"

const Privacy = ({ data }) => {
  const {
    title,
    headingTitle,
    headingSubtitle,
    description,
  } = data.contentfulGeneralPageTemplate

  return (
    <Layout>
      <SEO title={title} />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
            mb: [4, 5, 100],
          }}
        >
          <div>
            <Heading as="h1" sx={{ fontSize: 5, mb: 3, textAlign: "center" }}>
              {headingTitle}
            </Heading>
            <div sx={{ fontSize: 3, mb: [4, 5], textAlign: "center" }}>
              {headingSubtitle}
            </div>
            <ContentfulRichText
              sx={{ "& a": { color: "primary" } }}
              content={description}
            />
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulGeneralPageTemplate(title: { eq: "Privacy Policy" }) {
      id
      title
      headingTitle
      headingSubtitle
      description {
        raw
      }
    }
  }
`

export default Privacy